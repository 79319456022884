(function($, w, undefined) {

    function MemberStories() {
        this.swiper;
        this.detect();
    }

    MemberStories.prototype.detect = function() {
        this.swiper = new Swiper ('.module-member-stories .swiper-container', {
            // Optional parameters
            direction: 'horizontal',
            loop: false,
            spaceBetween: 24,
            slidesPerView: 2,

            breakpoints: {
                767: {
                    slidesPerView: 1,
                    spaceBetween: 14
                }
            },

            // Navigation arrows
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            }
        })
    };

    w.THF = w.THF || {};
    w.THF.Interface = w.THF.Interface || {};
    w.THF.Interface.MemberStories = new MemberStories();
})(jQuery, window);